/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 35px;
  margin-bottom: 35px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 10px;
  margin-bottom: 10px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #000;
  color: #000;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
video {
  float: left;
  width: 100%;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 3001;
  color: #000;
  font-family: 'Source Serif Pro', 'Times New Roman', serif;
  line-height: 1.25;
  font-size: 16px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #3EB1C8;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #3EB1C8;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 0;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 639px) {
  .table {
    font-size: 2.50391236vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(62, 177, 200, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 980px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.cb-home {
  display: block;
  width: 174px;
}
.logo {
  width: 100%;
  height: auto;
}
.cb-page-title {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  margin-top: 40px;
  margin-bottom: 10px;
}
@media (max-width: 639px) {
  .cb-page-title {
    padding: 0 10px;
  }
}
.cb-page-layout1 .cb-page-title {
  margin-top: 10px;
}
.cb-pages {
  float: left;
  width: 100%;
}
.cb-page {
  float: left;
  width: 100%;
}
#view .scroll-animated {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#view .scroll-animated.scroll-animated--active {
  opacity: 1;
  transform: translateY(0);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #000;
  font-family: 'Source Serif Pro', 'Times New Roman', serif;
  line-height: 1.25;
  font-size: 16px;
}
.cb-page-selected-layout5 .wrapper {
  background: #f6f5f1;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.cb-page-selected-layout6 .section {
  display: none;
}
.section--header {
  background: #fff;
  border-bottom: 1px solid transparent;
}
.section--multimood {
  display: none;
}
.cb-page-layout1 .section--multimood,
.cb-page-layout4 .section--multimood {
  display: block;
}
#edit .cb-page-layout1 .section--multimood,
#edit .cb-page-layout4 .section--multimood {
  margin-top: 0;
}
.cb-page-layout6 .section--multimood {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;
  display: block;
  box-sizing: border-box;
  border: 24px solid #fff;
}
.section--toplinks {
  border-bottom: 1px solid #49443f;
  display: none;
}
.cb-page-layout1 .section--toplinks,
.cb-page-layout4 .section--toplinks {
  display: block;
}
#view .cb-page-layout5 .section--north {
  margin-top: 160px;
}
#edit .cb-page-layout6 .section--north {
  display: block;
  z-index: 12;
  margin-top: 100px;
}
.section--south {
  margin-top: 35px;
  background: #e1f3f7;
  display: none;
}
.cb-page-layout3 .section--south,
.cb-page-layout4 .section--south,
.cb-page-layout5 .section--south {
  display: block;
}
.section--footlinks {
  background: #fff;
}
.section--footer {
  border-top: 1px solid #49443f;
  background: #fff;
}
.section--cmsbox {
  border-top: 1px solid #49443f;
  background: #fff;
}
.section--header .service_buchen {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  text-decoration: none;
  color: #3EB1C8;
}
@media (max-width: 639px) {
  .section--header .service_buchen {
    display: none;
  }
}
.moodcontent {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 5;
}
#toplinks {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#toplinks .meta {
  float: left;
  margin: 5px 0;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  text-decoration: none;
}
#toplinks .meta.service_buchen {
  display: none;
}
@media (max-width: 639px) {
  #toplinks .meta.service_buchen {
    display: block;
  }
}
#toplinks .meta.service_newsletter {
  color: #d799bc;
}
@media (max-width: 639px) {
  #toplinks .meta.service_newsletter {
    display: none;
  }
}
#toplinks .meta.service_newsletter:hover,
#toplinks .meta.service_newsletter:focus {
  color: #000;
}
@media (max-width: 510px) {
  #toplinks {
    flex-direction: column;
    align-items: center;
  }
}
.northcontent {
  float: left;
  width: 100%;
}
.subtitle {
  float: left;
  width: 100%;
  display: none;
  margin-top: 40px;
  text-align: center;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
}
.cb-page-layout1 .subtitle {
  display: block;
}
.toplink {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px 0 50px;
}
.toplink__anchor {
  float: left;
  width: 36px;
  height: 36px;
  background-size: 22px 36px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/toplink.svg);
  cursor: pointer;
}
.footarea {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footpart {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.section--footer .footpart {
  margin: 30px 0;
  justify-content: flex-start;
}
.footpart--3 {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .footpart--3 {
    flex-direction: column;
    align-items: flex-start;
  }
}
.foothome {
  float: left;
  width: 174px;
  margin: 96px 0;
  align-self: center;
}
.swiss-historic-hotels {
  position: absolute;
  left: 50%;
  top: 182px;
  z-index: 2;
  height: 56px;
  transform: translateX(-50%);
}
.swiss-historic-hotels img {
  height: 100%;
  width: auto;
}
.footlinks {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footlinks .meta {
  float: left;
  width: 38px;
  height: 38px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-phone-red.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .footlinks .meta {
    width: 30px;
    height: 30px;
  }
}
.footlinks .meta:first-child {
  margin-left: 0;
}
.footlinks .meta:last-child {
  margin-right: 0;
}
.footlinks .meta:hover {
  background-image: url(/images/icon-phone-blue.svg);
}
.footlinks .meta.service_mail {
  background-image: url(/images/icon-mail-red.svg);
}
.footlinks .meta.service_mail:hover {
  background-image: url(/images/icon-mail-blue.svg);
}
.footlinks .meta.service_instagram {
  background-image: url(/images/icon-instagram-red.svg);
}
.footlinks .meta.service_instagram:hover {
  background-image: url(/images/icon-instagram-blue.svg);
}
.footlinks .meta.service_facebook {
  background-image: url(/images/icon-facebook-red.svg);
}
.footlinks .meta.service_facebook:hover {
  background-image: url(/images/icon-facebook-blue.svg);
}
.footlinks .meta.service_twitter {
  background-image: url(/images/icon-twitter-red.svg);
}
.footlinks .meta.service_twitter:hover {
  background-image: url(/images/icon-twitter-blue.svg);
}
.footlinks .meta.service_map {
  background-image: url(/images/icon-map-red.svg);
}
.footlinks .meta.service_map:hover {
  background-image: url(/images/icon-map-blue.svg);
}
.footlinks .meta.service_360 {
  width: 44px;
  height: 44px;
  background-image: url(/images/icon-360-red.svg);
}
.footlinks .meta.service_360:hover {
  background-image: url(/images/icon-360-blue.svg);
}
@media (max-width: 1023px) {
  .footlinks .meta.service_360 {
    width: 36px;
    height: 36px;
  }
}
.newsletter {
  float: left;
  width: 100%;
}
.en .newsletter {
  display: none;
}
.newsletter__title {
  display: block;
  font-size: 30px;
  line-height: 1.2;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #3EB1C8;
  padding-bottom: 6px;
}
.newsletter__link {
  display: inline-block;
  font-size: 30px;
  line-height: 1.2;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #3EB1C8;
  padding-top: 6px;
  text-decoration: none;
}
.newsletter__text {
  display: block;
}
.services {
  float: left;
  width: 100%;
  text-align: center;
}
.services .meta {
  font-size: 30px;
  line-height: 1.2;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #3EB1C8;
  text-decoration: none;
}
.services .meta:hover,
.services .meta:focus {
  color: #6BBBAE;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard .org,
.vcard .adr,
.vcard .street-address {
  display: block;
}
.vcard a {
  color: #000;
  text-decoration: none;
}
.vcard a:hover,
.vcard a:focus {
  color: #3EB1C8;
}
.sustainable {
  float: left;
  width: 100px;
  margin-left: 30px;
}
@media (max-width: 1023px) {
  .sustainable {
    margin-top: 20px;
    margin-left: 0;
  }
}
.cmsbox {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 6px 0;
}
@media (max-width: 639px) {
  .cmsbox {
    justify-content: flex-start;
  }
}
.cmsbox .meta {
  float: left;
  margin: 4px 28px;
  text-decoration: none;
  color: #000;
  white-space: nowrap;
}
.cmsbox .meta:first-child {
  margin-left: 0;
}
.cmsbox .meta:last-child {
  margin-right: 0;
}
.cmsbox .meta:hover,
.cmsbox .meta:focus {
  color: #3EB1C8;
}
.cmsbox .meta.service_copyright:hover,
.cmsbox .meta.service_copyright:focus {
  color: #000;
}
@media (max-width: 1023px) {
  .cmsbox .meta {
    margin: 0 18px;
  }
}
@media (max-width: 639px) {
  .cmsbox .meta {
    margin: 0 12px;
  }
}
.farnorthwidth {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.cb-page-layout4 .farnorthwidth {
  background-color: #00263A;
}
#view .farnorthwidth {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#view.show-content .farnorthwidth {
  opacity: 1;
  transform: translateY(0);
}
.downlink {
  float: left;
  display: none;
  width: 26px;
  height: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/downlink.svg);
  cursor: pointer;
}
.cb-page-layout4 .downlink {
  display: block;
}
.stoerer {
  position: absolute;
  left: 100%;
  top: 100%;
  z-index: 2;
  width: 112px;
  margin-top: -80px;
  margin-left: -40px;
}
.cb-page-layout1 .stoerer {
  margin-left: -80px;
  margin-top: -100px;
}
.cb-page-layout6 .stoerer {
  display: none;
}
.close-gallery {
  position: fixed;
  top: 48px;
  right: 48px;
  height: 34px;
  width: 34px;
  z-index: 11;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/close-gallery.svg);
  cursor: pointer;
  display: none;
}
.cb-multimood {
  float: left;
  width: 100%;
}
.cb-expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 51.171875%;
}
.cb-slides {
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
.cb-slides .slide {
  width: 100%;
}
.cb-slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
.cb-expo div.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media (max-width: 1023px) {
  .cb-expo div.link {
    display: none;
  }
}
.cb-expo div.link table.link {
  display: none;
}
.cb-expo div.link a.link {
  float: left;
  width: 50%;
  height: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-size: 34px 34px;
  background-repeat: no-repeat;
  background-position: left -40px center;
  background-image: url(/images/mood-prev.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-expo div.link a.link.next {
  background-image: url(/images/mood-next.svg);
  background-position: right -40px center;
}
.cb-expo div.link a.link:hover {
  background-position: left 24px center;
}
.cb-expo div.link a.link:hover.next {
  background-position: right 24px center;
}
.cb-page-selected-layout6 .close-gallery {
  display: block;
}
.cb-page-selected-layout6 .cb-multimood {
  height: 100%;
}
.cb-page-selected-layout6 .cb-expo {
  padding-bottom: 0;
  height: 100%;
}
.cb-page-selected-layout6 .cb-slides {
  height: 100% !important;
}
.cb-page-selected-layout6 .slide {
  height: 100% !important;
}
.cb-page-selected-layout6 .cb-slides img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background-color: #fff;
  color: #000;
  font-family: 'Source Serif Pro', 'Times New Roman', serif;
  line-height: 1.25;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #000;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
.cb-cookie-warning--container {
  float: left;
  width: 882px;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}
@media (max-width: 639px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    text-align: center;
  }
}
.cb-cookie-warning--text {
  float: left;
}
.cb-cookie-warning--actions {
  float: left;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
@media (max-width: 639px) {
  .cb-cookie-warning--actions {
    margin-left: 0;
    margin-top: 10px;
  }
}
.cb-cookie-warning__button {
  float: left;
  display: block;
  background-color: #3EB1C8;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  margin-left: 20px;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  color: #fff;
  background-color: #4ab6cb;
  text-decoration: none;
  cursor: pointer;
}
.cb-cookie-warning__button:active {
  outline: none;
  background-color: #2f91a4;
}
.cb-cookie-warning__button:first-child {
  margin-left: 0;
}
h1 {
  display: block;
  text-align: center;
  font-size: 36px;
  line-height: 1.11111111;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
}
h2 {
  background: #3EB1C8;
  display: inline;
  font-size: 28px;
  line-height: 1.14285714;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  padding: 2px 0;
  box-shadow: 12px 0 0 #3EB1C8, -12px 0 0 #3EB1C8;
}
.south h2 {
  display: block;
  text-align: center;
  font-size: 36px;
  line-height: 1.11111111;
  background: none;
  box-shadow: none;
}
.farnorth h2 {
  font-size: 50px;
  line-height: 1.16;
  background: #fff;
  box-shadow: 28px 0 0 #fff, -28px 0 0 #fff;
}
.cb-page-layout4 .farnorth h2 {
  color: #6BBBAE;
  background: #00263A;
  box-shadow: 28px 0 0 #00263A, -28px 0 0 #00263A;
}
.side h2 {
  background: #173F35;
  box-shadow: 16px 0 0 #173F35, -16px 0 0 #173F35;
  font-size: 30px;
  line-height: 1.2;
}
.side .seam h2 {
  background: #00263A;
  box-shadow: 16px 0 0 #00263A, -16px 0 0 #00263A;
}
.side .pure h2 {
  background: #6F2B3F;
  box-shadow: 16px 0 0 #6F2B3F, -16px 0 0 #6F2B3F;
}
h3 {
  font-size: 21px;
  line-height: 1.23809524;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
}
h3 strong {
  color: #3EB1C8;
}
.south h3 {
  font-size: 30px;
  line-height: 1.2;
}
h4 {
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #3EB1C8;
}
strong {
  font-weight: bold;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.pale {
  font-size: 13px;
  line-height: 1.53846154;
  letter-spacing: 0.04em;
}
a {
  color: #6BBBAE;
  text-decoration: underline;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #000;
}
div.main {
  display: flex;
  flex-wrap: wrap;
}
.cb-page-layout1 div.main {
  margin-top: 25px;
}
div.side {
  margin-top: 54px;
  margin-bottom: 60px;
}
@media (max-width: 639px) {
  div.side {
    margin-top: 24px;
    margin-bottom: 30px;
  }
}
div.side div.unit {
  margin-top: 6px;
  margin-bottom: 6px;
}
div.side div.unit div.part {
  margin: 0;
}
div.side div.unit div.text {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  text-align: center;
}
#view div.side div.unit div.text {
  pointer-events: none;
}
div.side div.unit div.pict img {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.side div.unit:hover div.pict img {
  opacity: 0.2;
}
div.north {
  font-size: 20px;
}
div.north div.unit:first-child {
  margin-top: 0;
}
div.farnorth {
  width: 100%;
  text-align: center;
}
div.farnorth div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-page-layout4 div.farnorth div.unit {
  margin: 0;
}
div.farnorth div.unit div.head h2,
div.farnorth div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
div.farnorth div.unit div.part {
  width: 100%;
}
div.south {
  margin-top: 20px;
}
div.main div.unit {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1023px) {
  div.main div.unit {
    justify-content: flex-start;
  }
}
div.main div.seam,
div.main div.flat,
div.main div.edge {
  font-size: 13px;
  line-height: 1.38461538;
}
div.main div.seam div.body,
div.main div.flat div.body,
div.main div.edge div.body {
  padding: 10px 0;
}
div.main div.seam div.pict.tall,
div.main div.flat div.pict.tall,
div.main div.edge div.pict.tall,
div.main div.seam div.film.tall,
div.main div.flat div.film.tall,
div.main div.edge div.film.tall,
div.main div.seam div.cb-widget.tall,
div.main div.flat div.cb-widget.tall,
div.main div.edge div.cb-widget.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
div.main div.seam div.pict.tall:first-child,
div.main div.flat div.pict.tall:first-child,
div.main div.edge div.pict.tall:first-child,
div.main div.seam div.film.tall:first-child,
div.main div.flat div.film.tall:first-child,
div.main div.edge div.film.tall:first-child,
div.main div.seam div.cb-widget.tall:first-child,
div.main div.flat div.cb-widget.tall:first-child,
div.main div.edge div.cb-widget.tall:first-child {
  margin-top: -10px;
}
div.main div.seam div.pict.tall:last-child,
div.main div.flat div.pict.tall:last-child,
div.main div.edge div.pict.tall:last-child,
div.main div.seam div.film.tall:last-child,
div.main div.flat div.film.tall:last-child,
div.main div.edge div.film.tall:last-child,
div.main div.seam div.cb-widget.tall:last-child,
div.main div.flat div.cb-widget.tall:last-child,
div.main div.edge div.cb-widget.tall:last-child {
  margin-bottom: -10px;
}
div.main div.seam div.body {
  background: #b7e3ec;
}
div.main div.flat div.body {
  background: #d0e9e3;
}
div.main div.edge div.body {
  background: #ebd1e0;
}
.cb-page-layout5 div.main div.seam div.body {
  background: transparent;
  box-sizing: border-box;
  border: 1px solid #000;
}
div.side div.pure h2 {
  color: #3EB1C8;
}
div.side div.pure div.pict {
  background: #3EB1C8;
}
div.side div.seam h2 {
  color: #70c1a9;
}
div.side div.seam div.pict {
  background: #70c1a9;
}
div.side div.flat h2 {
  color: #d799bc;
}
div.side div.flat div.pict {
  background: #d799bc;
}
div.north div.seam {
  text-align: center;
}
div.south div.unit {
  background: #fff;
  padding: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
div.south div.unit.wide {
  background: none;
  padding: 0;
}
div.south div.unit.slim div.pict.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
div.south div.unit.slim div.pict.tall:first-child {
  margin-top: -10px;
  margin-bottom: 30px;
}
div.south div.unit.slim div.pict.tall:last-child {
  margin-top: 30px;
  margin-bottom: -10px;
}
div.fold div.foot {
  display: none;
}
div.fold div.less {
  float: left;
  width: 100%;
}
div.fold div.more {
  float: left;
  width: 100%;
  display: block !important;
  max-height: 0;
  overflow: hidden;
  margin-bottom: 46px;
}
div.fold.fold--open div.more {
  max-height: none;
}
div.fold div.link:last-child {
  position: absolute;
  bottom: 10px;
  left: 0;
}
div.fold .fold-toggle {
  float: left;
  width: 100%;
  height: 26px;
  display: block;
  background-size: 26px 14px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/folding.svg);
  cursor: pointer;
  position: relative;
}
div.fold .fold-toggle:before {
  display: none !important;
}
div.fold .fold-toggle:after {
  content: 'mehr';
  position: absolute;
  right: 36px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  font-size: 13px;
  line-height: 2;
  color: #000;
  font-family: 'Source Serif Pro', 'Times New Roman', serif;
  text-transform: none;
  font-weight: normal;
}
.en div.fold .fold-toggle:after {
  content: 'more';
}
div.fold .fold-toggle.fold-toggle--open {
  background-image: url(/images/folding--open.svg);
}
div.fold .fold-toggle.fold-toggle--open:after {
  content: 'weniger';
}
.en div.fold .fold-toggle.fold-toggle--open:after {
  content: 'less';
}
div.form {
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
}
div.form h4 {
  text-align: center;
  padding-top: 80px;
}
div.form .cb-form-required {
  display: block;
  font-size: 12px;
  padding-bottom: 20px;
  padding-top: 10px;
}
div.form input.text,
div.form textarea {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #000;
  min-height: 60px;
  padding: 5px 10px;
  background: transparent;
}
div.form select {
  height: 60px;
  background: transparent;
}
div.form label.name {
  min-height: 60px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
div.form div.tick span.name {
  padding: 0;
  min-height: 36px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
div.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  margin: 5px 0;
}
div.form div.tick div.ctrl > div label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  display: block;
  padding-left: 24px;
  line-height: 26px;
  margin: 0 !important;
}
#edit div.form div.tick div.ctrl > div label {
  position: relative;
  z-index: 3;
}
div.form div.tick div.ctrl > div input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
div.form div.foot {
  float: right;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
div.form input.submit {
  border: 1px solid #000;
  padding: 5px 20px 5px 36px;
  background-color: transparent;
  min-height: 60px;
  color: #d50057;
  background-size: 6px 6px;
  background-position: 20px 50%;
  background-repeat: no-repeat;
  background-image: url(/images/form-submit.svg);
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background-color: #d50057;
  color: #fff;
  border-color: #d50057;
  background-image: url(/images/form-submit-white.svg);
}
div.form input.submit:active {
  background-color: #a20042;
}
.text-section {
  float: left;
  width: 100%;
}
.text-section--h2 {
  box-sizing: border-box;
  padding: 0 12px;
}
.north .text-section--h2 {
  margin-top: 20px;
}
.south .text-section--h2 {
  padding: 0 16px;
}
.farnorth .text-section--h2 {
  padding: 0 28px;
}
.side .text-section--h2 {
  padding: 0 16px;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.load + div.load,
div.link + div.load,
div.load + div.link,
div.link + div.link {
  margin-top: -10px;
}
div.load a.load,
div.link a.load,
div.load a.open,
div.link a.open {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  line-height: 1.625;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #d50057;
}
div.load a.load:before,
div.link a.load:before,
div.load a.open:before,
div.link a.open:before {
  display: inline-block;
  content: '▶ ';
  transform: scale(0.7);
  margin-right: 4px;
}
div.load a.load:hover,
div.link a.load:hover,
div.load a.open:hover,
div.link a.open:hover,
div.load a.load:focus,
div.link a.load:focus,
div.load a.open:focus,
div.link a.open:focus {
  color: #000;
}
.table {
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  font-size: 21px;
  line-height: 1.23809524;
  text-transform: none;
}
div.pict a {
  position: relative;
  display: block;
}
div.pict a:after {
  content: '';
  position: absolute;
  right: 16px;
  top: 50%;
  width: 34px;
  height: 34px;
  margin-top: -17px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mood-next.svg);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-page-layout4 .side div.pict a:after,
.south div.pict a:after {
  display: none;
}
div.pict a:hover:after,
div.pict a:focus:after {
  right: 0;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.25em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  font-size: 16px;
  font-size: 1.6pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.25em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #3EB1C8;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #4ab6cb;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #2f91a4;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.25em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
.togglenavigation {
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 2;
  height: 16px;
  margin-top: -8px;
  cursor: pointer;
  padding-right: 36px;
  text-transform: uppercase;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-decoration: none;
  font-size: 16px;
  line-height: 1;
  color: #3EB1C8;
}
.togglenavigation:hover,
.togglenavigation:focus {
  color: #3EB1C8;
}
.closenavi {
  position: absolute;
  right: 5px;
  top: 67px;
  z-index: 2;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/naviclose.svg);
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(0);
}
body.cb-toggle-target-active .closenavi {
  transform: scale(1);
  transition: all 0.2s 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-scroll-triggered--active .closenavi {
  top: 17px;
}
@media (max-width: 1023px) {
  .closenavi {
    top: 52px;
  }
}
.t-line {
  position: absolute;
  width: 24px;
  height: 2px;
  background: #3EB1C8;
  top: 0;
  right: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.t-line--2,
.t-line--3 {
  top: 50%;
  margin-top: -1px;
}
.t-line--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .t-line--1 {
  transform: translateY(-15px);
  opacity: 0;
}
body.cb-toggle-target-active .t-line--2 {
  transform: rotate(-45deg);
}
body.cb-toggle-target-active .t-line--3 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .t-line--4 {
  transform: translateY(15px);
  opacity: 0;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100%;
  height: 100%;
  max-height: 0;
  overflow: hidden;
  background: #3EB1C8;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .navigation {
  max-height: 100%;
  overflow-y: scroll;
}
.navigation .desk {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.navicontent {
  float: left;
  width: 100%;
  margin: 74px 0;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .navicontent {
  opacity: 1;
  transform: translateY(0);
}
div.sub1 {
  float: left;
  margin: 4px 28px;
}
div.sub1 > .item {
  float: left;
  position: relative;
}
div.sub1 > .item:before {
  content: '|';
  display: inline-block;
  margin: 0 5px;
}
div.sub1 > .item.init:before {
  display: none;
}
div.sub1 > .item > .menu {
  display: inline-block;
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
}
div.sub1 > .item > .menu:hover {
  color: #3EB1C8;
}
div.sub1 > .item > .menu.path {
  font-weight: bold;
}
div.sub2 {
  width: 100%;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 60px;
  -moz-column-gap: 60px;
  column-gap: 60px;
}
@media (max-width: 1023px) {
  div.sub2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }
}
@media (max-width: 639px) {
  div.sub2 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
  }
}
div.sub2 .cb-toggle {
  display: none;
}
div.sub2 > .item {
  width: 100%;
  padding: 0 12px;
  display: inline-block;
  margin: 15px 0;
  box-sizing: border-box;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
div.sub2 > .item > .menu {
  font-size: 24px;
  line-height: 1.16666667;
  background: #fff;
  text-decoration: none;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #000;
  box-shadow: 12px 0 0 #fff, -12px 0 0 #fff;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu.path {
  color: #d50057;
}
div.sub3 {
  float: left;
  width: 100%;
}
div.sub3 > .item.init {
  margin-top: 10px;
}
div.sub3 > .item.exit {
  margin-bottom: 10px;
}
div.sub3 > .item > .menu {
  font-size: 17px;
  line-height: 1.52941176;
  text-decoration: none;
  font-family: 'Source Sans Pro', 'Helvetica', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  color: #000;
}
div.sub3 > .item > .menu:hover,
div.sub3 > .item > .menu.path {
  color: #d50057;
}
@media (max-width: 639px) {
  .togglenavigation {
    background: #fff;
    position: fixed;
    right: 5%;
    top: 20px;
    border: 5px solid #fff;
  }
  .tline {
    left: 5px;
  }
  .navigation .desk {
    display: block;
  }
  .closenavi {
    right: 10px;
    top: 5vw;
  }
  div.sub2 > .item {
    margin: 10px 0;
    position: relative;
    padding-right: 40px;
  }
  div.sub2 > .item.item-empty .cb-toggle {
    display: none !important;
  }
  div.sub2 > .item > .cb-toggle {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    background-size: 24px 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/images/mnavi.svg);
    cursor: pointer;
    transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  div.sub2 > .item > .cb-toggle.cb-toggle-active {
    transform: rotate(-180deg);
  }
  div.sub3 {
    max-height: 0;
    overflow: hidden;
  }
  div.sub2 > .item.cb-toggle-target-active div.sub3 {
    max-height: 500px;
    transition: all 10s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
}
.desk {
  max-width: 90%;
}
.cb-home {
  margin: 21px 0;
}
.northcontent {
  float: right;
  width: 84%;
}
#toplinks {
  margin: 15px 0;
}
.farnorthwidth {
  width: 200px;
}
.cb-page-layout4 .farnorthwidth {
  width: 160px;
  padding: 10px 0;
}
@media (max-width: 639px) {
  .section--footlinks div.footpart {
    width: 46%;
    order: 2;
  }
  .section--footlinks div.footpart.footpart--large {
    width: 100%;
    order: 1;
  }
  .section--footer .footarea {
    margin: 15px 0;
  }
  .section--footer div.footpart {
    margin: 15px 0;
  }
  .foothome {
    margin: 40px 0 96px;
  }
  .swiss-historic-hotels {
    top: 126px;
  }
  .footlinks {
    justify-content: space-between !important;
    margin-bottom: 40px;
  }
  .footlinks .meta {
    margin: 0;
  }
  .services {
    text-align: left;
  }
}
@media (max-width: 639px) {
  h1 {
    font-size: 26px;
    line-height: 1.15384615;
  }
  h2 {
    font-size: 24px;
    line-height: 1.25;
  }
  .farnorth h2 {
    font-size: 21px;
    line-height: 1.23809524;
    box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
  }
  .cb-page-layout4 .farnorth h2 {
    box-shadow: 10px 0 0 #00263A, -10px 0 0 #00263A;
  }
  .side h2 {
    font-size: 21px;
    line-height: 1.23809524;
    box-shadow: 10px 0 0 #173F35, -10px 0 0 #173F35;
  }
  .side .seam h2 {
    box-shadow: 10px 0 0 #00263A, -10px 0 0 #00263A;
  }
  .side .pure h2 {
    box-shadow: 10px 0 0 #6F2B3F, -10px 0 0 #6F2B3F;
  }
  .south h2 {
    font-size: 21px;
    line-height: 1.23809524;
  }
  .farnorth .text-section--h2 {
    padding: 0 10px;
  }
  .side .text-section--h2 {
    padding: 0 10px;
  }
  .south .text-section--h2 {
    padding: 0 10px;
  }
  .south h3 {
    font-size: 18px;
    line-height: 1.16666667;
  }
  .newsletter__title,
  .services .meta {
    font-size: 21px;
    line-height: 1.23809524;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part {
  width: 100%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 1%;
  margin-left: 1%;
  width: 98%;
}
.south .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.south .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.south > .slim .part {
  width: 90%;
}
div.farnorth {
  width: 100%;
}
div.south {
  width: 102%;
  margin-left: -1%;
}
div.south div.unit.wide {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
}
div.south div.unit.wide div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 639px) {
  .unit {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  div.main div.seam div.part,
  div.main div.flat div.part,
  div.main div.edge div.part {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */